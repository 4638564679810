let area = [
    {
        name: '吉林',
        id: 1,
        data: {
            P: 32299, //城镇居民人均可支配收入
            Q: 23394, //城镇居民人均消费性支出
            T: 36906.48, //丧葬费
        },
    },
    {
        name: '辽宁',
        id: 2,
        data: {
            P: 30172,
            Q: 22394,
            T: 34266.5,
        },
    },
]

export { area }
