function intervalCalculation(form, { coefficient }, { P, Q, T }) {
    let spiritualDamage = 50000
    let result = []

    if (form.project == '1') {
        result = [
            {
                name: '残疾赔偿金：',
                money: (() => {
                    if (form.victimsAge >= 75) {
                        return P * 5 * coefficient
                    } else if (form.victimsAge > 60) {
                        return P * (20 - (form.victimsAge - 60)) * coefficient
                    } else {
                        return P * 20 * coefficient
                    }
                })(),
            },
            {
                name: '住院伙食补助费：',
                money: 100 * form.InHospital,
            },
            {
                name: '精神损害抚慰金：',
                money: spiritualDamage * coefficient,
            },
            {
                name: '医疗费：',
                money: form.medicalFees,
            },
            {
                name: '辅助器具费：',
                money: form.auxiliaryInstrumentFee,
            },
            {
                name: '总计：',
                money: 0,
            },
        ]
    } else if (form.project == '2') {
        result = [
            {
                name: '丧葬费：',
                money: T,
            },
            {
                name: '死亡赔偿金：',
                money: (() => {
                    if (form.victimsAge >= 75) {
                        return P * 5
                    } else if (form.victimsAge > 60) {
                        return P * (20 - (form.victimsAge - 60))
                    } else {
                        return P * 20
                    }
                })(),
            },
            {
                name: '精神损害抚慰金：',
                money: spiritualDamage,
            },
            {
                name: '总计：',
                money: 0,
            },
        ]
    }

    let sum = 0
    result.forEach((element) => {
        sum = sum + element.money
    })

    result[result.length - 1].money = sum

    return result
}

export { intervalCalculation }
