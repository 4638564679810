<template>
    <div>
        <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
            <CalculatorSelect :form="form" label="area" :data="area" font="地区"></CalculatorSelect>

            <CalculatorSelect
                :form="form"
                label="project"
                :data="project"
                font="赔偿项目"
            ></CalculatorSelect>

            <template v-if="form.project == '1'">
                <CalculatorSelect
                    :form="form"
                    label="disabilityLevel"
                    :data="disabilityLevel"
                    font="伤残等级"
                ></CalculatorSelect>
            </template>

            <CalculatorInputNumber
                :form="form"
                label="victimsAge"
                suffix="岁"
                font="受害者年龄"
            ></CalculatorInputNumber>

            <template v-if="form.project == '1'">
                <CalculatorInputNumber
                    :form="form"
                    label="InHospital"
                    suffix="天"
                    font=" 住院天数"
                ></CalculatorInputNumber>

                <CalculatorInputNumber
                    :form="form"
                    label="medicalFees"
                    suffix="元"
                    font=" 医疗费"
                ></CalculatorInputNumber>

                <CalculatorInputNumber
                    :form="form"
                    label="auxiliaryInstrumentFee"
                    suffix="元"
                    font=" 辅助器具费"
                ></CalculatorInputNumber>
            </template>
        </a-form-model>
        <ButtonView @start="start" @reset="reset"></ButtonView>
        <Info></Info>
        <CalculatorResultClassification :result="result"></CalculatorResultClassification>
    </div>
</template>

<script>
    import CalculatorSelect from '@/components/PC/calculator/calculator-select.vue'
    import CalculatorInputNumber from '@/components/PC/calculator/calculator-inputNumber.vue'
    import ButtonView from '@/components/PC/calculator/button-view.vue'
    import CalculatorResultClassification from '@/components/PC/calculator/calculator-result-classification.vue'
    import Info from '@/components/PC/calculator/info.vue'
    import { disabilityLevel } from '../data/disabilityLevel'
    import { area } from '../data/area'
    import { intervalCalculation } from './intervalCalculation'

    export default {
        name: 'damage',
        components: {
            CalculatorSelect,
            ButtonView,
            Info,
            CalculatorInputNumber,
            CalculatorResultClassification,
        },
        data() {
            return {
                labelCol: { span: 5 },
                wrapperCol: { span: 17 },
                form: {
                    area: 1,
                    disabilityLevel: 10, //伤残等级
                    victimsAge: '', //受害者年龄
                    project: 1, //赔偿项目
                    medicalFees: '', //医疗费
                    InHospital: '', //住院天数
                    auxiliaryInstrumentFee: '', //辅助器具费
                },
                disabilityLevel,
                area,
                result: [],
                project: [
                    {
                        name: '残疾赔偿金',
                        id: 1,
                    },
                    {
                        name: '丧葬费',
                        id: 2,
                    },
                ],
            }
        },
        watch: {
            'form.project'(newVal) {
                this.form = {
                    area: this.form.area,
                    disabilityLevel: 10, //伤残等级
                    victimsAge: '', //受害者年龄
                    numberOfCommonResources: '', //共同抚养人数
                    rumorfulAge: '', //被抚养人年龄
                    project: newVal, //赔偿项目
                    medicalFees: '', //医疗费
                    InHospital: '', //住院天数
                    auxiliaryInstrumentFee: '', //辅助器具费
                }
                this.result = []
            },
        },
        methods: {
            start(callback) {
                for (let item in this.form) {
                    if (!this.form[item]) {
                        this.form[item] = 0
                    } else {
                        this.form[item] = Number(this.form[item])
                    }
                }
                let disabilityLevelData = disabilityLevel.filter((item) => {
                    return item.id == this.form.disabilityLevel
                })

                let areaData = area.filter((item) => {
                    return item.id == this.form.area
                })

                this.result = intervalCalculation(
                    this.form,
                    disabilityLevelData[0],
                    areaData[0].data
                )
                this.result.splice(0, 0)

                callback()
            },
            reset() {
                this.form = {
                    area: this.form.area,
                    disabilityLevel: 10, //伤残等级
                    victimsAge: '', //受害者年龄
                    numberOfCommonResources: '', //共同抚养人数
                    rumorfulAge: '', //被抚养人年龄
                    project: this.form.project, //赔偿项目
                    medicalFees: '', //医疗费
                    InHospital: '', //住院天数
                    auxiliaryInstrumentFee: '', //辅助器具费
                }
                this.result = []
            },
        },
    }
</script>
